import { useRole } from 'hooks'
import { t } from 'i18n'
import React, { useCallback, useEffect, useState } from 'react'
import { If } from 'react-extras'
import { useSelector } from 'react-redux'
import { Gender, getResortList } from 'services'
import { ApplicationState } from 'store'
import { Role, Seasons } from 'store/ducks'
import styled from 'styled-components'
import DateRangePicker from './DateRangePicker'
import DropdownFilter from './DropdownFilter'
import SliderFilter from './SliderFilter'
import { gender } from './static'

const Container = styled.form`
  width: 100%;
`

const GridSystem = styled.div`
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 5rem;

  &:last-child {
    margin-bottom: 0;
  }
`

enum Nationality {
  ALL = 'ALL',
  NORWEGIAN = 'Norwegian',
  SWEDISH = 'Swedish'
}

interface ResortProps {
  label: string
  value: string
}

export interface FilterDataProps {
  gender: Gender
  minAge: number
  maxAge: number
  nationality: Nationality
  resort: string
  season: Seasons
  endDate: string
  startDate: string
}

const StatisticFilters = () => {
  const user = useSelector((state: ApplicationState) => state.auth.data)
  const [age, setAge] = useState<number | number[]>([0, 100])

  const todayString: string = new Date().toISOString().split('T')[0]
  const today: Date = new Date(todayString)
  const firstDayOfYear = new Date(today.getFullYear(), 1, -29)
  const firstDayOfYearString: string = firstDayOfYear
    .toISOString()
    .split('T')[0]

  const [data, setData] = useState<FilterDataProps>({
    gender: Gender.ALL,
    minAge: 0,
    maxAge: 100,
    nationality: Nationality.ALL,
    resort: '',
    season: Seasons.WINTER,
    endDate: todayString,
    startDate: firstDayOfYearString
  })

  const [resorts, setResorts] = useState([] as ResortProps[])
  const isSysAdmin = useRole([Role.SYS_ADMIN])

  const loadResorts = useCallback(async () => {
    const resorts = await getResortList()
    const resortList = resorts.map(resort => ({
      label: resort.name,
      value: resort.SK
    }))

    setResorts([
      {
        label: t('all'),
        value: 'ALL'
      },
      ...resortList
    ])
  }, [])

  useEffect(() => {
    if (isSysAdmin) {
      loadResorts()
      // return
    }
    if (user?.resort?.SK) {
      const resort = user.resort.SK
      return setData(data => ({ ...data, resort }))
    }
  }, [isSysAdmin, loadResorts, user?.resort?.SK])

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>
  ) => {
    const { value, name } = e.currentTarget

    return setData({
      ...data,
      [name]: value
    })
  }

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault()
  }

  const handleSliderChange = (e: Event, newValue: number | number[]) => {
    return setAge(newValue)
  }

  const handleSliderChangeCommited = () => {
    let newMinAge, newMaxAge
    if (typeof age === 'number') {
      newMinAge = newMaxAge = age
    } else if (Array.isArray(age) && age.length === 2) {
      ;[newMinAge, newMaxAge] = age
    } else {
      console.error('Invalid age value:', age)
      return
    }

    setData({
      ...data,
      minAge: newMinAge,
      maxAge: newMaxAge
    })
  }

  const handleDateChange = (key: string, date: Date | null | undefined) => {
    return setData(data => ({ ...data, [key]: date }))
  }

  const renderFilters = () => (
    <Container onSubmit={handleOnSubmit}>
      <GridSystem>
        <If condition={isSysAdmin}>
          <DropdownFilter
            $border={false}
            items={resorts}
            name="resort"
            title={t('skiResort')}
            onChange={handleOnChange}
            value={data.resort}
          />
        </If>
      </GridSystem>
      <GridSystem>
        <SliderFilter
          title={t('age')}
          minVal={0}
          maxVal={100}
          value={age}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeCommited}
        />
        <DropdownFilter
          items={gender(t)}
          name="gender"
          title={t('gender')}
          onChange={handleOnChange}
          value={data.gender}
        />
        <DateRangePicker
          initialEndDate={data.endDate}
          initialStartDate={data.startDate}
          onChange={handleDateChange}
        />
      </GridSystem>
    </Container>
  )

  return { renderFilters, data }
}

export default StatisticFilters
